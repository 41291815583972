import React from "react";
import { Link } from "react-router-dom";

const InfoBlock = ({
  imgUrl,
  subtitle,
  title,
  description,
  buttonsOne,
  buttonsLinkOne,
  buttonsTwo,
  buttonsLinkTwo,
  featureName,
  number,
}) => {
  return (
    <>
      <section className="about-info gray-bg" id="about-info">
        <div className="container">
          <div className="wrapper-info-about">
            <div className="row align-items-center">
              {number % 2 === 1 ? (
                <div className="col-md-5">
                  <img src={imgUrl} alt={title} />
                </div>
              ) : (
                ""
              )}
              <div className="col-md-7">
                <div className="left-space">
                  <h6>{title}</h6>
                  <h2>{subtitle}</h2>
                  <div dangerouslySetInnerHTML={{ __html: description }}></div>
                  {buttonsOne ? (
                    <>
                      {window.location.pathname.includes(
                        "/cosmos-iq-business/request-demo"
                      ) ? (
                        <>
                          <Link
                            className="btn btn-primary btn-hover-heading-color"
                            to={buttonsLinkOne}
                          >
                            {buttonsOne}
                          </Link>
                        </>
                      ) : (
                        <>
                          {window.location.pathname.includes(
                            "/teach-on-cosmos-iq"
                          ) ? (
                            <>
                              <Link
                                className="btn btn-primary btn-hover-heading-color"
                                to={buttonsLinkOne}
                              >
                                {buttonsOne}
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                className="btn btn-primary btn-hover-heading-color"
                                to={{
                                  pathname: "/cosmos-iq-business/request-demo",
                                  search: `?featureName=${featureName}`,
                                }}
                              >
                                {buttonsOne}
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {buttonsTwo ? (
                    <Link
                      className="btn btn-outline-primary enterprise-twobtn"
                      to={buttonsLinkTwo}
                    >
                      {buttonsTwo}
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {number % 2 === 0 ? (
                <div className="col-md-5">
                  <img src={imgUrl} alt={title} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InfoBlock;
