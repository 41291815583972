import React from "react";
import { Link } from "react-router-dom";

const HeaderBanner = ({ imgUrl, subtitle, title, description, buttonsOne, buttonsLinkOne, buttonsTwo, buttonsLinkTwo }) => {
  return (
    <>
      <div
        className="section page-banner-section bg-color-1 about-banner gov-banner"
        style={{ backgroundImage: `url(${imgUrl})` }}
      >
        <div className="container">
          <div className="course-details-banner-content">
            {subtitle?
            <>
            <h2 className="title">
              <small>{subtitle}</small>
            </h2>
            </>
            :''}
            <h1 className="title">{title}</h1>
            <p>{description}</p>            
              <div className="courses-btn mt-4">
                {buttonsOne?
                <Link                    
                  className="btn btn-primary btn-hover-heading-color"
                  to={buttonsLinkOne}
                >
                  {buttonsOne}
                </Link>  
                :''}
                {buttonsTwo?
                <Link                    
                    className="btn btn-outline-primary enterprise-twobtn"
                    to={buttonsLinkTwo}
                  >
                  {buttonsTwo}
                </Link>     
                :''}           
                {/* className="btn btn-primary btn-hover-heading-color m-0" */}
              </div>            
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderBanner;
