import React, { useContext } from "react";
import ContactUsHeader from "./contact-us/ContactUsHeader";
import { Link } from "react-router-dom";
import ContactContext from "../components/global/ContactContext";

const ContactUsFinal = () => {
  const { contactData } = useContext(ContactContext);
  return (
    <>
      <ContactUsHeader
        title="How would you like t get help?"
        description="Chhose the option that is more convenient to you."
      />
      <div className="become-section-outer contactFinal-outer">
        <div className="row">
          <a
            className="col-lg-4 col-md-6"
            href="https://calendly.com/comosiq/30min"
            style={{ cursor: "auto" }}
          >
            <div
              className="contactFinal-become-inner c1"
              style={{ cursor: "pointer" }}
            >
              <div className="become-inner-inner">
                <div className="become-image">
                  <i className="fs-1 ri-phone-fill"></i>
                </div>
                <div className="become-content pt-1">
                  <h4 className="text-white fw-bold fs-6">Phone</h4>
                  <p
                    className="text-white fw-light pt-2"
                    style={{ fontSize: "0.8rem", lineHeight: "1rem" }}
                  >
                    Click to give us a call.
                  </p>
                </div>
              </div>
            </div>
          </a>
          <a
            className="col-lg-4 col-md-6"
            href="mailto: support@cosmosiq.ai"
            style={{ cursor: "auto" }}
          >
            <div
              className="contactFinal-become-inner c1"
              style={{ cursor: "pointer" }}
            >
              <div className="become-inner-inner">
                <div className="become-image">
                  <i className="fs-1 ri-mail-line"></i>
                </div>
                <div className="become-content pt-1">
                  <h4 className="text-white fw-bold fs-6">Email</h4>
                  <p
                    className="text-white fw-light pt-2"
                    style={{ fontSize: "0.8rem", lineHeight: "1rem" }}
                  >
                    Ask your query via email and we'll reply you soon.
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      {contactData ? (
        <div className="bg-light">
          <div className="container px-4 py-4">
            <h3>Your selection</h3>
            <p>
              <Link to="/contact-us">{contactData.menu}</Link>
              <span className="px-3">&gt;</span>
              <Link className="pt-3">{contactData.subMenu}</Link>
            </p>
          </div>
        </div>
      ) : (
        " "
      )}
    </>
  );
};

export default ContactUsFinal;
