import React from "react";

const CareerRelatedKnowledge = (props) => {
  return (
    <>
      <section className="about-us-trial career-text pb-0">
        <div className="container">
          <div className="col-md-12 mx-auto">
            <h3 className="heading-section">{props.data?(props.data.carrerSolutionHeading):''}</h3>
            <p></p>
            <p>
            {props.data?(props.data.careerSolutionDescription):''}
            </p>
            <p></p>
          </div>
        </div>
      </section>
      <section className="about-us-trial career-text">
        <div className="container">
          <div className="col-md-12 mx-auto">
            <small>{props.data?(props.data.benefitHeading):''}</small>
            <h3 className="heading-section">{props.data?(props.data.benefitSubHeading):''}</h3>
            <p className="mt-2">
            {props.data?(props.data.benefitDescription):''}
            </p>
          </div>
        </div>
        <div className="img-carrer-inner-banner pt-3">
          <div className="container">
            <img
              src={props.data?(props.data.benefitImage):''}
              alt="matriye capital"
            />
          </div>
          <div className="carrer-inner-section-text">
            <div className="container">
              <div className="col-md-12 mx-auto mt-5">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="heading-section">
                    {props.data?(props.data.boxOneHeading):''}
                    </h4>
                    <p></p>
                    <p>
                    {props.data?(props.data.boxOneDescription):''}
                    </p>
                    <p></p>
                  </div>
                  <div className="col-md-6">
                    <h4 className="heading-section">{props.data?(props.data.boxTwoHeading):''}</h4>
                    <p></p>
                    <p>
                    {props.data?(props.data.boxTwoDescription):''}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerRelatedKnowledge;
