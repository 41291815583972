import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import FooterComponent from "../../components/global/FooterComponent";
import NavbarComponent from "../../components/global/NavbarComponent";

function UserRouter() {  

  return (
    <>
      <NavbarComponent />
      <ScrollRestoration />
      <Outlet />
      <FooterComponent />
    </>
  );
}

export default UserRouter;
