import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import jobData from "./JobProfilesData.json";
import CareerDetails from "./CareerDetails";
import JobApplyModal from "../../components/page-wise-components/career/JobApplyModal";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";

const JobDetails = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleButtonClick = () => {
    // Show the modal when the button is clicked
    setModalVisible(true);
  };

  const { jobTitle } = useParams();

  const [jobDetails, setJobDetails] = useState([]);

  useEffect(() => {
    getJobDetailsData();
  }, []);

  const formData = new FormData();
  formData.append("jobTitleSlugUrl", jobTitle);

  const getJobDetailsData = async () => {
    await axios
      .post(window.apiBaseUrl + "/career/job-details", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          setJobDetails(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  if (!jobDetails) {
    return <CareerDetails />;
  }

  return (
    <>
      <Helmet>
        <title>{jobDetails.metaTitle ? jobDetails.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={
            jobDetails.metaDescription ? jobDetails.metaDescription : SITETITLE
          }
        />
        <meta
          name="keywords"
          content={
            jobDetails.metaKeywords ? jobDetails.metaKeywords : SITETITLE
          }
        />
      </Helmet>
      <section className="job-detail-banner">
        <div className="container">
          {isModalVisible && (
            <JobApplyModal
              showModal={isModalVisible}
              onClose={() => setModalVisible(false)}
              jobTitle={jobDetails.jobTitle}
              jobId={jobDetails.jobId}
            />
          )}
          <div className="row">
            <div className="col-lg-9">
              <div className="job-detail-content">
                <h1>{jobDetails.jobTitle}</h1>
                <ul className="job-detail-list">
                  {jobDetails.departmentName ? (
                    <li>
                      <i className="fa fa-briefcase"></i>
                      <span>{jobDetails.departmentName}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.cityName ? (
                    <li>
                      <i className="fa fa-map-pin"></i>
                      <span> {jobDetails.cityName} </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.postedDate ? (
                    <li>
                      <i className="fa fa-calendar"></i>
                      <span>{jobDetails.postedDate}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.salaryPackage ? (
                    <li>
                      <i className="fa fa-money"></i>
                      <span>{jobDetails.salaryPackage}</span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="job-detail-apply">
                <p>
                  Application Ends :<span>&nbsp;{jobDetails.jobStatus}</span>
                </p>
                <button
                  onClick={handleButtonClick}
                  className="button lets-start ml-0"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="job-desciption">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="job-details-page">
                <h2>Job Description</h2>
                <p>{jobDetails.jobDescription}</p>
                <h2>Key Responsiblity</h2>
                <p>{jobDetails.keyResponsibility}</p>
                <h2>Skill &amp; Experience</h2>
                <p>{jobDetails.skillAndExperience}</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="job-side-detail">
                <p>Job Overview</p>
                <ul>
                  {jobDetails.postedDate ? (
                    <li>
                      <i className="fa fa-calendar"></i>
                      <div className="overview-detail">
                        <h6>Date Posted</h6>
                        <p>{jobDetails.postedDate}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.departmentName ? (
                    <li>
                      <i className="fa fa-briefcase"></i>
                      <div className="overview-detail">
                        <h6>Department</h6>
                        <p>{jobDetails.departmentName}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.cityName ? (
                    <li>
                      <i className="fa fa-map-pin"></i>
                      <div className="overview-detail">
                        <h6>Location</h6>
                        <p>{jobDetails.cityName}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.salaryPackage ? (
                    <li>
                      <i className="fa fa-money"></i>
                      <div className="overview-detail">
                        <h6>Offered Salary</h6>
                        <p>{jobDetails.salaryPackage}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.expirationDate ? (
                    <li>
                      <i className="fa fa-calendar"></i>
                      <div className="overview-detail">
                        <h6>Expiration date</h6>
                        <p>{jobDetails.expirationDate}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.jobExperience ? (
                    <li>
                      <i className="fa fa-briefcase"></i>
                      <div className="overview-detail">
                        <h6>Experience</h6>
                        <p>{jobDetails.jobExperience}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.educationName ? (
                    <li>
                      <i className="fa fa-shield"></i>
                      <div className="overview-detail">
                        <h6>Qualification</h6>
                        <p>{jobDetails.educationName}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {jobDetails.jobType ? (
                    <li>
                      <i className="fa fa-user"></i>
                      <div className="overview-detail">
                        <h6>Job Type</h6>
                        <p>{jobDetails.jobType}</p>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default JobDetails;
