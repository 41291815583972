import "./MobileAppSection.css";
const InstructorMobileAppSection = ({ data }) => {
  return (
    <div className="mainCard">
      <div className="component">
        <div className="card1">
          <h1>{data.mainHeading}</h1>
          <p>{data.description}</p>
        </div>
        <div className="play">
          <h5>Get The App</h5>
          <div className="App">
            <a href="https://play.google.com/store/apps/details?id=com.cosmosiq.ai.faculty">
              <img id="play" src={data.playStoreImage} alt="" />
            </a>
            <a href=" https://apps.apple.com/us/app/cosmos-iq-faculty/id6480345638">
              <img id="play" src={data.appStoreImage} alt="" />
            </a>
          </div>
        </div>
      </div>

      <img className="img" id="phone1" src={data.sectionImage} alt="" />
      <img className="img" id="phone2" src={data.sectionImage2} alt="" />
      {/* <img className="img" src={phone1} id="phone1" />
      <img className="img" src={phone2} id="phone2" /> */}
    </div>
  );
};

export default InstructorMobileAppSection;
