import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import CardData from "./data/RequestData.json";

const WhyUse = ({ HeaderData, CardData }) => {
  return (
    <section className="why-use p-4">
      <Container>
        <Row>
          <Col className="text-center mt-4">
            <h2>{(HeaderData && HeaderData.whyUseCosmosTitle) || "N/A"}</h2>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="col-md-10 col-12 fw-normal">
            <div className="why-use-para text-center mt-4">
              <p className="fs-5">
                {(HeaderData && HeaderData.whyUseCosmosDescription) || "N/A"}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center mt-5">
          <div className="card-data-conatiner d-flex flex-wrap align-items-center justify-content-center gap-4">
            {CardData.map((item, key) => (
              <Col
                key={key}
                className="why-use-card d-flex gap-4 flex-column align-items-center text-center mb-4 col-md-3 col-12"
              >
                <div className="card-data-img">
                  <img src={item.icon} style={{ width: "100%" }} alt="" />
                  {/* <i className={`${item.icon} text-dark fs-1`}></i> */}
                </div>
                <div className="card-data-title">
                  <h3>{item.title}</h3>
                </div>
                <div className="card-data-text w-75">
                  <p>{item.description}</p>
                </div>
              </Col>
            ))}
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default WhyUse;
