import { Container, Row, Col } from "react-bootstrap";
import Png from "../../assets/image_copy/erp-img.png";
import { Link } from "react-router-dom";
const Banner = ({ staticDetails }) => {
  return (
    <section className="m-5">
      <Container className="Banner mt-4 mb-4  rounded-4 p-4">
        <Row className="no-gutters">
          <Col className="col-md-6  col-12 p-5">
            <h2 className="text-white fs-4">
              {staticDetails.sectionOneHeading
                ? staticDetails.sectionOneHeading
                : "N/A"}
            </h2>
            <p className="my-4 text-white">
              {staticDetails.sectionOneDescription
                ? staticDetails.sectionOneDescription
                : "N/A"}
            </p>
            <Link
              to={
                staticDetails.sectionOneButtonLink
                  ? staticDetails.sectionOneButtonLink
                  : "https://www.google.com"
              }
              className="btn btn-primary btn-hover-success"
            >
              {staticDetails.sectionOneButtonName
                ? staticDetails.sectionOneButtonName
                : "N/A"}
            </Link>
          </Col>
          <Col className="col-md-6 col-12 px-0 d-flex align-items-center justify-content-center">
            <div style={{ height: "320px" }}>
              <img
                className="banner-img "
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={
                  staticDetails.sectionOneImage
                    ? staticDetails.sectionOneImage
                    : Png
                }
                alt={
                  staticDetails.sectionOneHeading
                    ? staticDetails.sectionOneHeading
                    : "N/A"
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
