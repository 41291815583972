import React from 'react'
import { Link } from 'react-router-dom'

const Grid = (props) => {
    return (
        <>
            {Array.isArray(props.data)?(
            <div className="row">
                {props.data.map((filter, index)=>(
                <div className="col-xl-4 col-md-6 d-flex">
                    <div className="single-course flex-fill">
                        <div className="course-image">
                            <Link to={`/courses/${filter.courseNameSlugUrl}/${filter.subjectNameSlugUrl}`}>
                                <img
                                    src={filter.subjectPicture}
                                    alt={filter.subjectName}
                                    loading="lazy"
                                />
                            </Link>
                        </div>
                        <div className="courses-content">
                            <div className="top-meta">
                                <Link className="tag">
                                {filter.courseLevel}
                                </Link>
                                <span className="price">
                                    <span className="sale-price">
                                        Rs. {filter.subjectPrice}
                                    </span>
                                </span>
                            </div>
                            <h3 className="title">
                                <Link to={`/courses/${filter.courseNameSlugUrl}/${filter.subjectNameSlugUrl}`}>
                                {filter.subjectName}
                                </Link>
                            </h3>
                            <span className="author-name">
                            {filter.departmentName}
                            </span>
                            <span className="course-name-tag">{filter.courseName}</span>
                        </div>
                        <div className="courses-meta">
                            <div className="course-new-list-outer">
                                {filter.courseContentLessonsCount?(
                                <div className="course-new-list-inner">
                                    <i className="fa fa-book"></i>
                                    <p>{filter.courseContentLessonsCount}</p>
                                </div>
                                ):''}
                                <div className="course-new-list-inner">
                                    <i className="fa fa-users"></i>
                                    <p>100 Seats</p>
                                </div>
                                {filter.courseDuration?(
                                <div className="course-new-list-inner">
                                    <i className="fa fa-calendar"></i>
                                    <p>{filter.courseDuration}&nbsp;{filter.courseDurationIn}</p>
                                </div>
                                ):''}
                            </div>
                            <div className="add-btn">
                                <Link to={`/courses/${filter.courseNameSlugUrl}/${filter.subjectNameSlugUrl}`} className="add-cart-btn">
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            ):''}
        </>
    )
}

export default Grid