import React from "react";

const BecomeWithComponent = (props) => {
  // Function to generate a random color in hex format
  const getRandomColor = () => {
    const getRandomComponent = () => Math.floor(Math.random() * 156) + 100; // Adjust the range for lighter colors
    const red = getRandomComponent().toString(16).padStart(2, "0");
    const green = getRandomComponent().toString(16).padStart(2, "0");
    const blue = getRandomComponent().toString(16).padStart(2, "0");
    return `#${red}${green}${blue}`;
  };

  return (
    <>
      <section className="become-section">
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">{props.data}</h2>
              </div>
            </div>
          </div>
          <div className="become-section-outer">
            {props.dataList ? (
              <div className="row">
                {props.dataList.map((points, index) => (
                  <div key={index} className="col-lg-2 col-md-4">
                    <div
                      className="become-inner c1"
                      style={{ backgroundColor: getRandomColor() }}
                    >
                      <div className="become-inner-inner">
                        <div className="become-image">
                          <img
                            className="lazyloaded"
                            alt={points.title}
                            src={points.imagePath}
                          />
                        </div>
                        <div className="become-content">
                          <h5 style={{ cursor: "pointer" }}>{points.title}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BecomeWithComponent;
