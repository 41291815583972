import React from "react";

const CareerInfoBlock = (props) => {
  return (
    <>
      <section className="about-us-trial">
        <div className="container">
          <div className="feature-intro space-top text-left">
            <div className="row align-items-center">
              <div className="col-md-12">
                <h2 className="heading-section">{props.data.beliverHeading}</h2>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={props.data.beliverImageOne}
                      alt="career-planning"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-6">
                    <img
                      src={props.data.beliverImageTwo}
                      alt="career-planning"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                    <img
                      src={props.data.beliverImageThree}
                      className="img-fluid"
                      alt="bg-1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="heading-section passionate">
                  {props.data.beliverSubHeading}
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.data.beliverDescription,
                  }}
                ></div>
                <div className="recruiters-profile ">
                  <h3 className="mb-3">Recruiters Profile</h3>
                  <img
                    src="https://www.matriyeacademy.com/website-assets/images/naukri-logo.png"
                    alt="bg-2"
                  />
                  <button
                    className="btn btn-outline-primary"
                    // href="https://www.naukri.com/recruiters/kumarsanjeev"
                  >
                    Click here to apply
                  </button>

                  <div className="mt-3">
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/linkedin.png"
                      alt="bg-3"
                    />
                    <button
                      className="btn btn-outline-primary"
                      // href="https://www.linkedin.com/company/matriye-academy/jobs/?viewAsMember=true"
                    >
                      Click here to apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="wrapper-brochures">
                  <div className="brochures-down-load">
                    <h4>{props.data.brochuresHeading}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.data.brochuresDescription,
                      }}
                    ></div>
                  </div>
                  <div className="download-btn">
                    <ul className="files">
                      <li>
                        <a
                          href={props.data.brochuresPDF}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {props.data.brochuresButtonName}
                          <span className="material-symbols-outlined">
                            file_download
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerInfoBlock;
