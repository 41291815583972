import React from "react";

const KeyFeatures = ({ pageTitle, pageSubTitle, content = [], customClass, customId }) => {  
  
  return (
    <>
      <section className="about-us-trial career-text feature-intro" id={customId}>
        <div className="container">
          <h2 className="title head-choose">
            <small>{pageSubTitle}</small>
            <br />
            {pageTitle}
          </h2>
          <div className="col-md-12">
          {Array.isArray(content)?(
            <>
            <div className="row">              
              {content.map((feature, index) => (                
                <div key={index} className={`${customClass} d-flex `}>
                  <div className="feature-inner flex-fill">
                    <div className="icon-box">
                      <img src={feature.whyChooseUsIcon} alt="bg1" />
                    </div>
                    <div className="text-box-new">
                      <h3>{feature.whyChooseUsTitle}</h3>                      
                      <div dangerouslySetInnerHTML={{ __html: feature.whyChooseUsDescription }}></div>
                    </div>
                  </div>
                </div>
              ))}              
            </div>
            </>
            ):''}

          </div>
        </div>
      </section>
    </>
  );
};

export default KeyFeatures;
