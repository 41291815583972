import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import LeadingData from "./data/Leading.json";

const Leading = ({ HeaderData, LeadingData }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(LeadingData[0])[0]
  );

  return (
    <section className="leading-brand my-5">
      <Container>
        <Row>
          <Col className="col-md-12 col-12 d-flex align-items-center justify-content-center">
            <div className="marketing-camp-head  text-center">
              <h1>
                {(HeaderData && HeaderData.testimonialHeading) || "N/A"}
              </h1>
              <p style={{ fontSize: "1.3rem", color: "#000" }} className="mt-4">
                {(HeaderData && HeaderData.testimonialDescription) ||
                  "N/A"}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="p-4">
        <Row>
          <Col className="col-md-12 col-12">
            {LeadingData.map((buttons, buttonsIndex) => (
              <div
                key={buttonsIndex}
                className="d-flex flex-wrap gap-2 align-items-center justify-content-center p-4 "
              >
                {Object.keys(buttons).map((buttonsName, index) => (
                  <div key={index} className="leading-buttons bg-dark">
                    <button
                      className="btn btn-primary btn-hover-success"
                      onClick={() => setSelectedCategory(buttonsName)}
                    >
                      {buttonsName}
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          {selectedCategory &&
            LeadingData.map((category, categoryIndex) => {
              if (category[selectedCategory]) {
                return category[selectedCategory].map((item, itemIndex) => (
                  <Col key={itemIndex} className="col-md-4 col-12 mt-4">
                    <div className="leading-cards p-4">
                      <div className="leading-img ">
                        <img src={item.img} alt="" />
                      </div>
                      <div className="leading-text mt-4">
                        <p>{item.description}</p>
                      </div>
                      <div className="leading-avtar mt-5 d-flex flex-wrap align-items-center gap-3">
                        <img src={item.avtar} alt="" />
                        <div className="">
                          <h6>{item.name}</h6>
                          <p>{item.designation}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ));
              }
              return null;
            })}
        </Row>
      </Container>
    </section>
  );
};

export default Leading;
