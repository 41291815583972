import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/website/fonts/font-awesome.min.css";
import "./assets/website/css/style.min.css";
import "./assets/website/css/style.css";
import "./assets/website/css/mobile.css";
import "./assets/website/css/chat.css";
import "./assets/website/css/price.css";
import "./assets/website/css/flaticon.css";
// import 'jquery/dist/jquery.min.js';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Home from "../src/pages/home/Home";
import AboutUs from "../src/pages/about-us/AboutUs";
import ForEnterprise from "../src/pages/for-enterprise/ForEnterprise";
import ForGovernment from "../src/pages/for-government/ForGovernment";
import HelsFaqs from "../src/pages/help-faqs/HelpFaqs";
import Blogs from "../src/pages/blogs/Blogs";
import NoticeBoard from "../src/pages/notice-board//NoticeBoard";
import Career from "../src/pages/career/Career";
import CareerDetails from "../src/pages/career/CareerDetails";
import ContactUs from "../src/pages/ContactUs";
import ContactUsFinal from "./pages/ContactUsFinal";
import TermsAndCondition from "../src/pages/tnc/TermsAndCondition";
import BlogDetails from "../src/pages/blogs/BlogDetails";
import Cart from "../src/pages/Cart";
import Checkout from "../src/pages/Checkout";
import JobDetails from "../src/pages/career/JobDetails";
import Filter from "../src/pages/Filter";
import CourseDetails from "../src/pages/CourseDetails";
import DepartmentList from "../src/pages/DepartmentList";
import RefundPolicy from "../src/pages/refund-policy/RefundPolicy";
import Cookies from "../src/pages/cookies/Cookies";
import PrivacyPolicy from "../src/pages/privacy-policy/PrivacyPolicy";
import UserRouter from "./config/users/UserRouter";
import Instructor from "./pages/instructor/Instructor";
import DigitalPlanFilter from "./pages/instructor/DigitalPlanFilter";
import Student from "./pages/student/Student";
import Faculty from "./pages/student/Faculty";
import { ERPLINK, FACULTYLINK, STUDENTLINK } from "./constants";
import PlanAndPricing from "./pages/PlanAndPricing/PlanAndPricing";
import ContactContext from "./components/global/ContactContext";
import RequestDemo from "./pages/RequestDemo/RequestDemo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserRouter />,
    children: [
      { index: true, element: <Home /> },
      {
        index: true,
        path: "courses/:courseNameSlugUrl/:subjectNameSlugUrl",
        element: <CourseDetails />,
      },
      {
        // index: true,
        path: "filter",
        element: <Filter />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        // index: true,
        path: "cosmos-iq-business",
        element: <ForEnterprise />,
      },
      {
        path: "for-government",
        element: <ForGovernment />,
      },
      {
        path: "blogs",
        element: <Blogs />,
      },
      {
        index: true,
        path: "blogs/:categoryTitle/:blogTitle",
        element: <BlogDetails />,
      },
      {
        path: "help-faqs",
        element: <HelsFaqs />,
      },
      {
        path: "notice-board",
        element: <NoticeBoard />,
      },
      {
        path: "career",
        element: <Career />,
      },
      {
        path: "jobs",
        element: <CareerDetails />,
      },
      {
        index: true,
        path: "jobs/:jobTitle",
        element: <JobDetails />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "reach-out",
        element: <ContactUsFinal />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        // index: true,
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndCondition />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "refund-policy",
        element: <RefundPolicy />,
      },
      {
        path: "cookie-policy",
        element: <Cookies />,
      },
      {
        path: "teach-on-cosmos-iq",
        element: <Instructor />,
      },
      {
        index: true,
        path: "department/:departmentNameSlugUrl",
        element: <DepartmentList />,
      },
      {
        path: "digital-plan-filter",
        element: <DigitalPlanFilter />,
      },
      {
        path: "student",
        element: <Student />,
      },
      {
        path: "faculty",
        element: <Faculty />,
      },
      {
        path: "erp",
        element: <Faculty />,
      },
      {
        path: "*", // 404 - Error Redirect to Homepage
        element: <Navigate to="/" />,
      },
      {
        path: "/cosmos-iq-business/plan-pricing", // 404 - Error Redirect to Homepage
        element: <PlanAndPricing />,
      },
      {
        path: "/cosmos-iq-business/request-demo",
        element: <RequestDemo />,
      },
      
    ],
  },
]);

function App() {  
  
  useEffect(() => {
    const receiveMessage = (event) => {
      // Ensure message is from the expected origin

      if (event.origin === STUDENTLINK || event.origin === FACULTYLINK || event.origin === ERPLINK) {
        const data = event.data;
        
        if (data.authUser) {
          // console.log("authUser if calling");
          localStorage.setItem("authUser", data.authUser);
        } else {
          // console.log("authUser else calling");
          localStorage.setItem("authUser", "");
        }
        // if (typeof data.authUserType !== "undefined") {
        if (data.authUserType) {
          // console.log("authUserType if calling");
          localStorage.setItem("authUserType", data.authUserType);
        } else {
          // console.log("authUserType else calling");
          localStorage.setItem("authUserType", "");
        }
      }
    };
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const [contactData, setContactData] = useState({ menu: "", subMenu: "" });

  return (
    <ContactContext.Provider value={{ contactData, setContactData }}>
      <div>
        <div className="main-wrapper">
          <RouterProvider router={router} />
          {/* <Router>
          <React.StrictMode>
            <NavbarComponent />
            <UserRoutes />
            <FooterComponent />
          </React.StrictMode>
        </Router> */}
        </div>
      </div>
    </ContactContext.Provider>
  );
}

export default App;
