import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { SITETITLE } from "../../constants";
import Pagination from "../pagination";

const DigitalPlanFilter = () => {
  const [digitalPlanFilterData, setDigitalPlanFilterData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const currentDigitalPlanFilterData = digitalPlanFilterData.packageList
    ? digitalPlanFilterData.packageList.slice(startIdx, endIdx)
    : [];

  const formData = new FormData();
  formData.append("limit", 10);
  formData.append("offset", 5);
  formData.append("type", "class-wise-package");

  useEffect(() => {
    getDigitalPlanFilterData();
  }, []);

  const getDigitalPlanFilterData = async () => {
    await axios
      .post(
        window.apiBaseUrl + "for-instructor/digital-plan-filter",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {          
          setDigitalPlanFilterData(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const totalPages = Math.ceil(
    digitalPlanFilterData.totalRecords / itemsPerPage
  );

  return (
    <>
      <Helmet>
        <title>{SITETITLE} :: Digital Plan Filter</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="section section-padding mt-5">
        <div className="container">
          <br />
          <div className="course-list-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-lg-12">
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="grid">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sidebar-widget">
                          <div className="widget-search">
                            <form
                              autocomplete="off"
                              method="get"
                              accept-charset="utf-8"
                            >
                              <input
                                className="form-control"
                                type="text"
                                value=""
                                name="q"
                                id="q"
                                placeholder="Search for full package,single class and single subject"
                                required
                              />
                              <input
                                className="form-control"
                                type="hidden"
                                value="subject-wise-package"
                                name="type"
                                id="type"
                              />
                              <button type="submit">
                                <i className="fa fa-search"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="sidebar-widget">
                          <div className="widget-search">
                            <a
                              href="http://localhost/user-development/digital-plan-filter?type=subject-wise-package"
                              className="btn btn-primary"
                            >
                              Clear Search
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                    {Array.isArray(currentDigitalPlanFilterData) &&
                    currentDigitalPlanFilterData.length > 0 ? (
                      <div className="row">
                        {currentDigitalPlanFilterData.map((packages, index) => (
                          <div key={index} className="col-lg-4 mt-5">
                            <div className="sidebar-details-wrap mt-3">
                              <div className="sidebar-details-video-description">
                                <div className="sidebar-video">
                                  <img
                                    src={packages.packageImage}
                                    alt={packages.packageId}
                                    style={{ height: "210px", width: "100%" }}
                                  />
                                </div>
                                <div
                                  className="sidebar-description"
                                  style={{
                                    padding: "20px 20px 0px !important",
                                  }}
                                >
                                  <strong>{packages.title}</strong>
                                  <div className="price">
                                    <span className="sale-price">
                                      Rs. {packages.price}{" "}
                                    </span>
                                    <span className="regular-price"></span>
                                  </div>
                                  <div className="courses-btn">
                                    <a
                                      href="http://localhost/user-development/digital-plan-filter/buy/eccbc87e4b5ce2fe28308fd9f2a7baf3"
                                      data-type="buy"
                                      data-id="eccbc87e4b5ce2fe28308fd9f2a7baf3"
                                      className="btn btn-primary btn-hover-heading-color web-event"
                                    >
                                      Buy it now
                                    </a>
                                    <a
                                      className="btn btn-outline-primary enterprise-twobtn web-event"
                                      data-type="cart"
                                      data-id="eccbc87e4b5ce2fe28308fd9f2a7baf3"
                                      href="http://localhost/user-development/digital-plan-filter/add-to-cart/eccbc87e4b5ce2fe28308fd9f2a7baf3"
                                    >
                                      Add To Cart
                                    </a>
                                  </div>
                                  {Array.isArray(packages.features) ? (
                                    <ul className="description-list">
                                      {packages.features.map((list, index) => (
                                        <li key={index}>
                                          <i className="fa fa-check"></i>{" "}
                                          {list.featureName}
                                          <span>{list.featureDescription}</span>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalPlanFilter;
