import { Container, Row, Col } from "react-bootstrap";
// import data from "./Data/ButtonData.json";
import Card from "./Cards/Card";
import CompareFeature from "./CompareFeature";
import ZoomOneExpirence from "./ZoomOneExpirence";
import Banner from "./Banner";
import Stories from "./Stories";
import Faqs from "./Faqs";
import Switch from "./Cards/Switch";
// import Atlassian from "./Atlassian";
import { useRef, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Select from "react-select";

import FeatureSectionComponent from "../../components/page-wise-components/home/FeatureSectionComponent";

const getFeaturedInData = async () => {
  const response = await axios.get(window.apiBaseUrl + "home/featured-in");
  return response.data.data;
};
const getCountryData = async () => {
  const response = await axios.get(window.apiBaseUrl + "master/country");
  return response.data.data;
};
const SubComponent = (props) => {
  const { staticDetails, planPricing, addOnsPlan, faqList } = props;

  const { data: featuredInData } = useQuery("featuredIn", getFeaturedInData, {
    cacheTime: 0,
  });
  const { data: countryData } = useQuery("countryData", getCountryData, {
    cacheTime: 0,
  });

  const myTargetComponentRef = useRef(null);

  const [switchButton, setSwitchButton] = useState("month");

  const toggleSwitch = () => {
    // Toggle between "month" and "year"
    setSwitchButton(switchButton === "month" ? "year" : "month");
  };

  // if (error) return <div>An error occurred: {error.message}</div>;

  // Filter out objects where currencySymbol and currencyValue are not null
  const filteredData =
    countryData &&
    countryData.filter(
      (item) => item.currencySymbol !== null && item.currencyValue !== null
    );

  const indianFlag =
    countryData && countryData.find((item) => item.countryId === "101");

  const options =
    filteredData &&
    filteredData.map((country) => ({
      currencySymbol: country.currencySymbol,
      flagImg: country.flagImg,
      value: parseInt(country.currencyValue),
      label: `${country.countryName} ${
        country.currencySymbol ? "(" + country.currencySymbol + ")" : ""
      }`,
    }));

  // const defaultValue = "India";
  // const [defaultCountry, setDefaultCountry] = useState("India");

  const [countryDetails, setCountryDetails] = useState(null);
  const handleSelectChange = (selectedOption) => {
    setCountryDetails(selectedOption);
  };

  return (
    <section className="subCompnent bg-white rounded-top-5">
      <Container fluid>
        {planPricing && planPricing ? (
          <>
            <Row>
              <Col className="col-md-5 col-12 mt-2 mx-4 ">
                <div
                  className="monthly d-flex flex-wrap gap-3 align-items-center"
                  onClick={toggleSwitch}
                >
                  <span>Monthly</span>
                  <Switch />
                  <span>Anually</span>
                  <p className="bg-success text-white px-2 rounded-4">
                    {(staticDetails &&
                      staticDetails.planPricingDiscountLabel) ||
                      "N/A"}
                  </p>
                </div>
              </Col>
              <Col className="col-12 col-md-6  d-flex align-items-center justify-content-md-end">
                <div className="country d-flex gap-3 align-items-center ">
                  <Select
                    className="bg-transparent border-0 text-dark mt-2"
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption)
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "200px",
                        border: "1px solid #000",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: "pointer",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#000000",
                      }),
                    }}
                    options={
                      options &&
                      options.map((option) => ({
                        ...option,
                        label: (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={option.flagImg}
                              alt="Flag"
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            {option.label}
                          </div>
                        ),
                      }))
                    }
                    placeholder={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={(indianFlag && indianFlag.flagImg) || ""}
                          alt="Flag"
                          style={{ width: "20px", marginRight: "5px" }}
                        />
                        India (₹)
                      </div>
                    }
                  />
                </div>
              </Col>
            </Row>
            <Card
              countryDetails={countryDetails}
              plan={switchButton}
              data={planPricing ? planPricing.list : null}
              activePlanDetails={
                planPricing ? planPricing.activePlanDetails : null
              }
            />
          </>
        ) : (
          ""
        )}
        {addOnsPlan ? (
          <Row className="mt-4">
            <Col className="text-center">
              <button
                className="btn btn-primary btn-hover-success fs-5 "
                onClick={() =>
                  myTargetComponentRef.current?.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              >
                Views Add-ons <i className="ri-arrow-down-line fs-5"></i>
              </button>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Container>
      <CompareFeature planPricing={planPricing} />
      <div ref={myTargetComponentRef}></div>
      {addOnsPlan ? (
        <ZoomOneExpirence
          staticDetails={staticDetails}
          addOnsPlan={addOnsPlan}
        />
      ) : (
        ""
      )}
      {staticDetails ? (
        staticDetails.sectionOneHeading ? (
          <Banner staticDetails={staticDetails} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {staticDetails ? (
        staticDetails.sectionTwoNameAndDesignation ? (
          <Stories staticDetails={staticDetails} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {featuredInData ? (
        <FeatureSectionComponent
          data={featuredInData.featuredInHeading}
          dataList={featuredInData.featureList}
        />
      ) : (
        ""
      )}
      {/* <Atlassian /> */}
      {faqList ? <Faqs faqList={faqList} /> : ""}
    </section>
  );
};

export default SubComponent;
