import React, { useState } from "react";
import BlogHeaderBanner from "../../components/page-wise-components/blog/BlogHeaderBanner";
import BlogListBody from "../../components/page-wise-components/blog/BlogListBody.jsx";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";

const Blogs = () => {
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const handleLoadingComplete = () => {
    setIsLoading(false); // Update loading state when blog list is rendered
  };

  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <LoadingBar progress={isLoading ? 100 : 0} color="#f11946" height={3} />
      <div style={{ visibility: isLoading ? "visiable" : "visible" }}>
        <BlogHeaderBanner />
        <BlogListBody onLoadingComplete={handleLoadingComplete} />
      </div>
    </>
  );
};
export default Blogs;
