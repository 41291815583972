import React from "react";
import { Link } from "react-router-dom";

const FeaturedTopicComponent = (props) => {
  return (
    <>
      <div className="section section-padding featured-topics">
        <div className="container">
          <div className="section-title  row align-items-center justify-content-md-between">
            <div className="col-md-6">
              <h2 className="title">
                <span>Featured topics</span> by department
              </h2>
            </div>
            <div className="col-md-6">
              <Link
                to="/filter"
                className="btn btn-primary btn-hover-heading-color"
              >
                View More Course
              </Link>
            </div>
          </div>

          {props.data?(
          <div className="row">
            {props.data.map((featuredTopics, index) => (
              <div key={index} className="col-xl-3 col-md-6 mt-3">
                <div className="bg-wrapper">
                  <h3>{featuredTopics.departmentName}</h3>
                  {featuredTopics.courses?(
                  <ul>
                    {featuredTopics.courses.map((departments, index) => (
                    <li key={index}>
                      <Link to={`/filter?q=${departments.courseName
                                      .toLowerCase()
                                      .replace(/\s+/g, "+")}`}>
                        <span>{departments.courseName}</span>
                      </Link>
                      <small>{departments.totalSubject} subjects</small>
                    </li>
                    ))}
                  </ul>
                  ):''}
                </div>
              </div>
            ))}
          </div>
          ):''}

        </div>
      </div>
    </>
  );
};

export default FeaturedTopicComponent;
