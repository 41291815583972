import { Container, Row, Col } from "react-bootstrap";
// import data from "./Data/ZoomeExpirence.json";
import ZoomCards from "./Cards/ZoomCard1";
import Switch from "./Cards/Switch";
import { useState } from "react";

const ZoomOneExpirence = (props) => {
  const { staticDetails, addOnsPlan } = props;
  const [switchButton, setSwitchButton] = useState("month");

  const toggleSwitchAdd = () => {
    // Toggle between "month" and "year"
    setSwitchButton(switchButton === "month" ? "year" : "month");
  };

  return (
    <section className="my-5">
      <Container fluid className="">
        <Row>
          <Col className="text-center col-md-12 col-12 mb-4">
            <h1>{staticDetails ? staticDetails.addOnPlansHeading : "N/A"}</h1>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col className=" col-md-5 col-12 mt-2 mx-4 ">
            <div
              className="monthly d-flex flex-wrap gap-3 align-items-center"
              onClick={toggleSwitchAdd}
            >
              <span>Monthly</span>
              <Switch />
              <span>Anually</span>
              <p className="bg-success text-white px-2 rounded-4">
                {staticDetails ? staticDetails.addOnPlansDiscountLabel : "N/A"}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <ZoomCards plan={switchButton} data={addOnsPlan} />
    </section>
  );
};

export default ZoomOneExpirence;
