import React from 'react'
import { Link } from 'react-router-dom';

// var createGuest = require("cross-domain-storage/guest");
// var createHost = require("cross-domain-storage/host");

// createHost([
//   {
//     origin: "http://localhost:3001",
//     allowedMethods: ['get', 'set', 'remove'],
//   },
//   {
//     origin: "http://localhost:3000",
//     allowedMethods: ['get', 'set', 'remove'],
//   },
// ]);

const infoBlockData = {
    "infoImgUrl": "https://img.freepik.com/free-photo/computer-security-with-login-password-padlock_107791-16191.jpg?w=900&t=st=1704698828~exp=1704699428~hmac=e9c5c41a145e064f664dec1dc55a24b7518201cde346d8fdddbfebfe5a3bfa3b",
    "infoSubtitle": "Cosmos IQ EdTech",
    "infoTitle": "Student Dashboard",
    "infoDescription": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    "buttonsOne": "Contact Sales",
    "buttonsLinkOne": "buttonsLinkOne"    
}

const Student = () => {
    
    const handleAccessToken = () => {
      // if(window.location.href === "http://localhost:3001/") {  
      //   var bazStorage = createGuest("http://localhost:3000");
      //   bazStorage.get('authUser',function(error,value) {
      //     try {
      //       if(value){                                  
      //         localStorage.setItem('authUser',value);            
      //         window.location.reload();
      //       }
      //     } catch (err) {
      
    //   toast.error(err.response.data.message, {
    //     autoClose: 5000,
    //     theme: "colored",
    //   });
      //     }        
      //   });
      // } else {          
      //   bazStorage = createGuest("http://localhost:3001");
      //   bazStorage.get('authUser',function(error,value) {
      //     try {
      //       if(value){                    
      //         localStorage.setItem('authUser',value);            
      //         window.location.reload();
      //       }
      //     } catch (err) {
        // toast.error(err.response.data.message, {
        //     autoClose: 5000,
        //     theme: "colored",
        //   });
      //     }
      //   });
      // }
    }

    return (
        <>
        <br />
        <br />
            
            <section className="about-info gray-bg" id="about-info">
                <div className="container">
                    <div className="wrapper-info-about">
                        <div className="row align-items-center">
                        <div className="col-md-5">
                            <img src={infoBlockData.infoImgUrl} alt={infoBlockData.infoTitle} />
                        </div>
                        <div className="col-md-7">
                            <div className="left-space">
                            <h6>{infoBlockData.infoTitle}</h6>
                            <h2>{infoBlockData.infoSubtitle}</h2>                  
                            <div dangerouslySetInnerHTML={{ __html: infoBlockData.infoDescription }}></div>

                            <button onClick={handleAccessToken} className="btn btn-primary btn-hover-heading-color">Access Student Dashboard</button>

                            {infoBlockData.buttonsOne?
                            <Link                        
                                className="btn btn-outline-primary enterprise-twobtn"
                                to={infoBlockData.buttonsLinkOne}
                            >
                            {infoBlockData.buttonsOne}
                            </Link>
                            :''}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default Student