import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Blogs from "./Blogs";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import { useFormik } from "formik";
import { forBlogComment } from "../../config/formValidation";
import { useQuery } from "react-query";

const getProfileData = async () => {
  if (localStorage.getItem("authUser")) {
    const response = await axios.get(window.apiBaseUrl + "auth/profile", {
      headers: {
        AccessToken: "Bearer " + localStorage.getItem("authUser"),
      },
    });
    return response.data.data;
  }
};

const BlogDetails = () => {
  const {
    data: profile,
    error,
    isLoading,
  } = useQuery("profileData", getProfileData);

  const profileData = profile;

  const { categoryTitle } = useParams();
  const { blogTitle } = useParams();

  const [blogDetails, setBlogDetails] = useState([]);
  const [blogComments, setBlogComments] = useState([]);
  const [blogListData, setBlogList] = useState([]);
  const [blogId, setBlogId] = useState("");

  useEffect(() => {
    if (blogTitle) {
      getBlogDetailsData();
    }
    if (blogId) {
      getBlogCommentListData(blogId);
      getBlogListData();
    }
  }, [blogTitle, blogId]);

  const formData = new FormData();
  const formData2 = new FormData();
  formData.append("blogTitleSlugUrl", blogTitle);
  formData.append("blogCategorySlugUrl", categoryTitle);

  const getBlogDetailsData = async () => {
    await axios
      .post(window.apiBaseUrl + "blog/blog-details", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          setBlogDetails(res.data.data);
          setBlogId(res.data.data.blogData.blogId);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getBlogCommentListData = async (val) => {
    await axios
      .post(
        window.apiBaseUrl + "blog/blog-comment-list",
        { blogId: val },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setBlogComments(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  formData2.append("limit", 10);
  formData2.append("offset", 0);

  const getBlogListData = async () => {
    await axios
      .post(window.apiBaseUrl + "blog/blog-list", formData2, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          setBlogList(res.data.data);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const {
    values,
    errors,
    dirty,
    isValid,
    isSubmitting,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      commentByName: (profileData && profileData.fullName) || "",
      commentByEmail: (profileData && profileData.emailId) || "",
      commentDescrption: "",
      blogId: blogId,
    },
    validationSchema: forBlogComment,
    onSubmit: async (values, action) => {
      await axios
        .post(window.apiBaseUrl + "career/apply-for-job", values, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              autoClose: 5000,
              theme: "colored",
            });
            action.resetForm();
          } else {
            // toast.warn(res.data.message, {
            //   autoClose: 5000,
            //   theme: "colored",
            // });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        });
    },
  });

  if (!blogDetails.blogData) {
    return <Blogs />;
  }

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>
          {blogDetails.blogData.metaTitle
            ? blogDetails.blogData.metaTitle
            : SITETITLE}
        </title>
        <meta
          name="description"
          content={
            blogDetails.blogData.metaDescription
              ? blogDetails.blogData.metaDescription
              : SITETITLE
          }
        />
        <meta
          name="keywords"
          content={
            blogDetails.blogData.metaKeywords
              ? blogDetails.blogData.metaKeywords
              : SITETITLE
          }
        />
      </Helmet>
      <div
        className="section page-banner-section bg-color-1 blog-banner"
        style={{
          backgroundImage: `url(https://matriyeacademy.com/website-assets/images/bg_blog_banner.webp)`,
        }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1 className="title">{blogDetails.blogData.blogTitle}</h1>
            <ul className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <Link to="/blogs">Blogs</Link>
              </li>
              <li className="breadcrumb-item active">
                {blogDetails.blogData.blogTitle}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="blog-wrapper-02">
            <div className="row justify-content-between">
              <div className="col-lg-8">
                <div className="blog-details-wrapper">
                  <div className="blog-details-image">
                    <Link to="/blogs">
                      <img
                        src={blogDetails.blogData.blogImage}
                        loading="lazy"
                        alt={blogDetails.blogData.blogId}
                      />
                    </Link>
                    <span className="tags">
                      {blogDetails.blogData.blogCategoryName}
                    </span>
                  </div>
                  <div className="blog-details-content">
                    <div className="meta">
                      <Link>
                        <i className="fa fa-user-o"></i>
                        {blogDetails.blogData.authorName}
                      </Link>
                      <Link>
                        <i className="fa fa-calendar"></i>
                        {blogDetails.blogData.dateOfBlog}
                      </Link>
                    </div>
                    <h3 className="title">{blogDetails.blogData.blogTitle}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blogDetails.blogData.blogDescriptionOne,
                      }}
                    ></div>
                    <blockquote className="blockquote">
                      <div className="blockquote-icon">
                        <svg
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 32 32"
                          style={{ enableBackground: "new 0 0 32 32" }}
                          xmlSpace="preserve"
                        >
                          <g>
                            <polygon points="0,4 0,28 12,16 12,4" />
                            <polygon points="20,4 20,28 32,16 32,4" />
                          </g>
                        </svg>
                      </div>
                      <div className="blockquote-content">
                        <p>"{blogDetails.blogData.blogQuote}"</p>
                      </div>
                    </blockquote>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blogDetails.blogData.blogDescriptionTwo,
                      }}
                    ></div>
                  </div>
                  <div className="blog-tags-social">
                    {blogDetails.blogData.blogTags ? (
                      <div className="blog-tags">
                        <span className="label">Tags:</span>
                        <ul>
                          {blogDetails.blogData.blogTags.map((tags, index) => (
                            <li key={index}>
                              <Link>{tags}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="blog-social">
                      <ul className="social social-box">
                        <li className="twitter">
                          <i className="fa fa-twitter">{""}</i>
                        </li>
                        <li className="linkedin">
                          <i className="fa fa-linkedin">{""}</i>
                        </li>
                        <li className="facebook">
                          <i className="fa fa-facebook-f">{""}</i>
                        </li>
                        <li className="instagram">
                          <i className="fa fa-instagram">{""}</i>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog-details-pagination">
                    <div className="previous-post">
                      {blogDetails.prevBlog ? (
                        <div className="blog-details-post">
                          <div className="details-post-thumb">
                            <Link
                              to={`/blogs/${blogDetails.prevBlog.blogCategoryNameSlugUrl}/${blogDetails.prevBlog.blogTitleSlugURL}`}
                            >
                              <i className="fa fa-angle-left"></i>
                              <img
                                src={blogDetails.prevBlog.blogImage}
                                alt={blogDetails.prevBlog.blogId}
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <div className="details-post-content">
                            <h4 className="title">
                              <Link
                                to={`/blogs/${blogDetails.prevBlog.blogCategoryNameSlugUrl}/${blogDetails.prevBlog.blogTitleSlugURL}`}
                              >
                                {blogDetails.prevBlog.blogTitle}
                              </Link>
                            </h4>
                            <span className="date">
                              <i className="fa fa-calendar"></i>
                              &nbsp;{blogDetails.prevBlog.dateOfBlog}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="next-post">
                      {blogDetails.nextBlog ? (
                        <div className="blog-details-post">
                          <div className="details-post-content text-end">
                            <h4 className="title">
                              <Link
                                to={`/blogs/${blogDetails.nextBlog.blogCategoryNameSlugUrl}/${blogDetails.nextBlog.blogTitleSlugURL}`}
                              >
                                {blogDetails.nextBlog.blogTitle}
                              </Link>
                            </h4>
                            <span className="date">
                              <i className="fa fa-calendar"></i>
                              &nbsp;{blogDetails.nextBlog.dateOfBlog}
                            </span>
                          </div>
                          <div className="details-post-thumb">
                            <Link
                              to={`/blogs/${blogDetails.nextBlog.blogCategoryNameSlugUrl}/${blogDetails.nextBlog.blogTitleSlugURL}`}
                            >
                              <img
                                src={blogDetails.nextBlog.blogImage}
                                alt={blogDetails.nextBlog.blogId}
                                loading="lazy"
                              />
                              <i className="fa fa-angle-right"></i>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="commtent-wrapper">
                    {blogComments.commentList ? (
                      <div className="comment-box">
                        <h3 className="comment-title">
                          Comment ({blogComments.totalComments})
                        </h3>
                        <ul className="commtent-items">
                          {blogComments.commentList.map((comments, index) => (
                            <li key={index}>
                              <div className="single-comment">
                                <div className="comment-author">
                                  <img
                                    src={comments.profilePicture}
                                    alt={comments.commentByName}
                                    loading="lazy"
                                  />
                                </div>
                                <div className="comment-content">
                                  <h4 className="name">
                                    {comments.commentByName}
                                  </h4>
                                  <p>{comments.commentDescrption}</p>
                                  <div className="meta">
                                    <div className="like-buton">
                                      {comments.likeCount > 0 ? (
                                        <b>{comments.likeCount}&nbsp;</b>
                                      ) : (
                                        ""
                                      )}
                                      {comments.likeStatus ? (
                                        <span
                                          data-target="#login"
                                          data-toggle="modal"
                                        >
                                          {comments.likeStatus > 0 ? (
                                            <i
                                              className="fa  fa-thumbs-up"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-thumbs-o-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                          &nbsp;Likes
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <a
                                      className="reply collapsed"
                                      href="/"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapseOne${comments.blogCommentId}`}
                                      aria-expanded="false"
                                      aria-controls={`collapseOne${comments.blogCommentId}`}
                                    >
                                      {comments.replyCount > 0 ? (
                                        <b>{comments.replyCount}&nbsp;</b>
                                      ) : (
                                        ""
                                      )}
                                      <i className="fa fa-comment"></i> Reply
                                    </a>
                                    <span className="date text-right">
                                      {comments.postedAt}
                                    </span>
                                    <div
                                      className="accordion"
                                      id={`accordionExample${comments.blogCommentId}`}
                                    >
                                      <div className="accordion-item border-none">
                                        <div
                                          id={`collapseOne${comments.blogCommentId}`}
                                          className="accordion-collapse collapse"
                                          aria-labelledby="headingOne"
                                          data-bs-parent={`#accordionExample${comments.blogCommentId}`}
                                        >
                                          <div className="accordion-body">
                                            <div className="contact-form">
                                              <form
                                                action="https://t-tacdmy.matriyeacademy.com/blog/leave-commment-reply/action"
                                                autoComplete="off"
                                                method="post"
                                                id="frmLeaveCommentBlogReply"
                                                name="frmLeaveCommentBlogReply"
                                                acceptCharset="utf-8"
                                              >
                                                <input
                                                  type="hidden"
                                                  name="blogCommentId"
                                                  value="VmtaYVJrOVdRbEpRVkRBOQ=="
                                                />
                                                <div className="form-group">
                                                  <textarea
                                                    className=""
                                                    name="commentReply"
                                                    placeholder="Comment"
                                                  ></textarea>
                                                </div>
                                                <div className="form-group mb-0">
                                                  <a
                                                    href="/"
                                                    data-target="#login"
                                                    data-toggle="modal"
                                                    className="btn btn-primary text-white btn-hover-heading-color"
                                                  >
                                                    Submit
                                                  </a>
                                                </div>
                                              </form>
                                              {comments.commentReply
                                                ? comments.commentReply.map(
                                                    (replies, index) => (
                                                      <div
                                                        key={index}
                                                        className="single-comment"
                                                      >
                                                        <div className="comment-author">
                                                          <img
                                                            src={
                                                              replies.profilePicture
                                                            }
                                                            alt={
                                                              replies.fullName
                                                            }
                                                            loading="lazy"
                                                          />
                                                        </div>
                                                        <div className="comment-content">
                                                          <h4 className="name">
                                                            {replies.fullName}
                                                          </h4>
                                                          <p>
                                                            {
                                                              replies.commentReply
                                                            }
                                                          </p>
                                                          <div className="meta">
                                                            <span className="date">
                                                              {replies.postedAt}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="comment-form">
                      <h3 className="comment-title">Leave a Comment</h3>
                      <div className="comment-form-wrapper">
                        <form
                          autoComplete="off"
                          acceptCharset="utf-8"
                          noValidate="novalidate"
                          onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <input
                              type="hidden"
                              className="form-control"
                              name="blogId"
                              value={values.blogId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="col-md-6">
                              <div className="single-form">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Name*"
                                  name="commentByName"
                                  value={values.commentByName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.commentByName &&
                                touched.commentByName ? (
                                  <p className="text-danger">
                                    {errors.commentByName}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-form">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Your Email*"
                                  name="commentByEmail"
                                  value={values.commentByEmail}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.commentByEmail &&
                                touched.commentByEmail ? (
                                  <p className="text-danger">
                                    {errors.commentByEmail}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="single-form">
                                <textarea
                                  className="form-control"
                                  placeholder="Your Message*"
                                  name="commentDescrption"
                                  value={values.commentDescrption}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                ></textarea>
                                {errors.commentDescrption &&
                                touched.commentDescrption ? (
                                  <p className="text-danger">
                                    {errors.commentDescrption}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="single-form">
                                <button
                                  type="submit"
                                  disabled={
                                    !(dirty && isValid && !isSubmitting)
                                  }
                                  className="btn btn-primary text-white btn-hover-heading-color"
                                >
                                  {isSubmitting
                                    ? "Please wait..."
                                    : "Post Comment"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="sidebar-wrap">
                  <div className="sidebar-widget">
                    <div className="widget-search widget-search-desktop">
                      <form
                        autoComplete="off"
                        action={"/blogs"}
                        method="get"
                        acceptCharset="utf-8"
                      >
                        <input
                          className="form-control"
                          type="text"
                          name="q"
                          placeholder="Search for blog name, tags"
                        />
                        <button type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="sidebar-widget sidebar-widget-about">
                    <h3>About us</h3>
                    <div className="textwidget">
                      <p>
                        Cosmos IQ EdTech offers career-relevant online
                        E-learning programs the Academic, Professional Courses,
                        and Competitive exams.{" "}
                      </p>
                      <h5 className="name">Social Media</h5>
                      <ul className="social-box">
                        <li className="twitter">
                          <i className="fa fa-twitter">{""}</i>
                        </li>
                        <li className="linkedin">
                          <i className="fa fa-linkedin">{""}</i>
                        </li>
                        <li className="facebook">
                          <i className="fa fa-facebook-f">{""}</i>
                        </li>
                        <li className="instagram">
                          <i className="fa fa-instagram">{""}</i>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {blogListData.blogList ? (
                    <div className="sidebar-widget">
                      <h3 className="widget-title">Popular Posts</h3>
                      <div className="widget-post">
                        {blogListData.blogList
                          .slice(0, 3)
                          .map((blogs, index) => (
                            <div key={index} className="single-mini-post">
                              <div className="mini-post-image">
                                <Link
                                  to={`/blogs/${blogs.blogCategoryNameSlugUrl}/${blogs.blogTitleSlugURL}`}
                                >
                                  <img
                                    loading="lazy"
                                    src={blogs.blogImage}
                                    alt={blogs.blogId}
                                  />
                                </Link>
                              </div>
                              <div className="mini-post-content">
                                <h5 className="title">
                                  <Link
                                    to={`/blogs/${blogs.blogCategoryNameSlugUrl}/${blogs.blogTitleSlugURL}`}
                                  >
                                    {blogs.blogTitle}
                                  </Link>
                                </h5>
                                <span className="date">
                                  <i className="fa fa-calendar"></i>
                                  &nbsp;{blogs.dateOfBlog}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <hr />
                  <div className="sidebar-widget">
                    <h3 className="widget-title">Tags</h3>
                    <div className="widget-tags">
                      <ul>
                        <li>
                          <a href="/">Academic</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
