import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import data from "./data/Marketing.json";

const MarketingCampaings = ({ HeaderData, data }) => {
  const [selectedItem, setSelectedItem] = useState(data[0]);
  const [activeIndex, setActiveIndex] = useState(data[0]);
  const updateContent = (item) => {
    setSelectedItem(item);
    setActiveIndex(item);
  };
  return (
    <section className="marketing-camp p-4">
      <Container>
        <Row>
          <Col className="col-md-12 col-12 d-flex align-items-center justify-content-center">
            <div className="marketing-camp-head  text-center">
              <h1>
                {(HeaderData && HeaderData.marketingCampaignTitle) || "N/A"}
              </h1>
              <p style={{ fontSize: "1.3rem", color: "#000" }} className="mt-4">
                {(HeaderData && HeaderData.marketingCampaigDescription) ||
                  "N/A"}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Row>
          <Col className="col-md-4 col-12 mt-4">
            {data.map((item, index) => (
              <div
                key={index}
                className={`automated-market mb-4 p-4 ${
                  activeIndex === item ? "active" : ""
                }`}
                onClick={() => updateContent(item)}
                style={{
                  backgroundColor: activeIndex === item ? "#5E4CC8" : "",
                  color: activeIndex === item ? "#fff" : "",
                }}
              >
                <p>{item.title}</p>
              </div>
            ))}
          </Col>
          <Col className="col-md-8 col-12 d-flex flex-column align-items-center justify-content-center ">
            <div className="market-img">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
                src={selectedItem ? selectedItem.image : "default image url"}
                alt=""
              />
            </div>
            <div className="market-para text-center w-75">
              <p className="text-dark">
                {selectedItem
                  ? selectedItem.description
                  : "Default paragraph text"}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MarketingCampaings;
