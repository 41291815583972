import React from "react";

const AboutUsCeoInfo = ({ data }) => {
  return (
    <>
      <section className="founder" id="our-ceo">
        <div className="container">
          <div className="row ">
            <div className="col-md-4">
              <div className="founder-image">
                <img src={data.founderPicture} alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="founder-content">
                <h4>{data.founderTitle}</h4>
                <p>{data.founderDescription}</p>
                <h6>{data.founderName}</h6>
                <p className="designation-founder">{data.founderDesignation}</p>
                <div className="founder-social">
                  <i>
                    {" "}
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/gmail-logo.png"
                      alt=""
                    />
                  </i>
                  <i>
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/crunchbase-logo.png"
                      alt=""
                    />
                  </i>
                  <i>
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/linkedin-logo.png"
                      alt=""
                    />
                  </i>
                  <i>
                    <img
                      src="https://www.matriyeacademy.com/website-assets/images/yourstory-logo.png"
                      alt=""
                    />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsCeoInfo;
