import { Carousel, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Carousel.css";

const MyCarousel = ({ items }) => {
  return (
    <>
      {items ? (
        <Carousel data-bs-theme="dark" controls={false} className="carousel">
          {items.map((page, key) => (
            <Carousel.Item key={key} interval={2000}>
              <Container
                fluid
                className="carousel-container d-flex align-items-center justify-content-center"
                style={{
                  height: "82vh",
                }}
              >
                <Row className="">
                  <Col
                    className={`col-12 col-md-7 px-5 d-flex flex-column justify-content-center  `}
                  >
                    <h6
                      className="pre-title fw-normal pb-1"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {page.subHeading}
                    </h6>
                    <h1 className="title fs-2  carousel-heading   fw-bold">
                      {page.heading}
                    </h1>
                    <p
                      className=" mt-3 "
                      dangerouslySetInnerHTML={{ __html: page.description }}
                    ></p>

                    <div className="buttons mt-4 d-flex gap-3 mb-4">
                      <Link
                        to={`${
                          window.location.pathname.includes(
                            "/cosmos-iq-business"
                          )
                            ? "/cosmos-iq-business/request-demo?featureName=" +
                              page.featureNameSlugUrl
                            : page.buttonsLinkOne
                        }`}
                        className="btn btn-primary btn-hover-heading-color "
                      >
                        {page.buttonsOne}
                      </Link>
                      <Link
                        to={page.buttonsLinkTwo}
                        className="btn btn-primary btn-hover-heading-color "
                      >
                        {page.buttonsTwo}
                      </Link>
                    </div>
                  </Col>
                  <Col className="image-carousel col-md-5 col-12 mb-4">
                    <div className="carousel-image px-2">
                      <img
                        className="carousel-img"
                        style={{ height: "100%", width: "100%" }}
                        src={page.featuredImagePath}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        ""
      )}
    </>
  );
};

export default MyCarousel;
