import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import PromoCodeModal from "../components/core/cart/PromoCodeModal";
import { useFormik } from "formik";
import { forApplyCode } from "../config/formValidation";
import { toast } from "react-toastify";
import { STUDENTLINK } from "../constants";

const Cart = () => {
  const {
    isEmpty,
    totalUniqueItems,
    items,
    cartTotal,
    removeItem,
    updateItem,
  } = useCart();
  const [recommend, setRecommended] = useState([]);
  const [promoCodeList, setPromoCodeList] = useState([]);

  const initialValues = {
    cartTotal: cartTotal,
    couponCode: "",
  };

  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [couponPrice, setCouponPrice] = useState("");
  const [totalOfferAmount, setTotalOfferAmount] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const [isModalVisible, setModalVisible] = useState(false);

  const handleButtonClick = () => {
    // Show the modal when the button is clicked
    setModalVisible(true);
  };

  useEffect(() => {
    getPromoCodeData();
    if (!isEmpty) {
      const para = {
        subjectId: items[0].id,
      };
      getRecommendedData(para);
    }
  }, [isEmpty, items, removeItem]);

  const getRecommendedData = async (para) => {
    await axios
      .post(window.apiBaseUrl + "cart/recommended-subject", para, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          setRecommended(res.data.data);
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const getPromoCodeData = async (para) => {
    await axios
      .get(window.apiBaseUrl + "cart/promo-codes")
      .then((res) => {
        if (res.status === 200) {
          setPromoCodeList(res.data.data);
        } else {
          setPromoCodeList("");
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
  };

  const handleUpdateCart = (couponData) => {
    const itemsFirstId = items[0].id;
    updateItem(itemsFirstId, {
      couponData,
    });
  };

  const {
    values,
    errors,
    dirty,
    isValid,
    isSubmitting,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: forApplyCode,
    onSubmit: async (values, action) => {
      await axios
        .post(window.apiBaseUrl + "/cart/promo-codes/check", values, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              autoClose: 2000,
              theme: "colored",
            });
            setIsDiscountApplied(true);
            setCouponPrice(res.data.data.couponPrice);
            setCouponCode(res.data.data.couponCode);
            setTotalOfferAmount(res.data.data.totalOfferAmount);
            handleUpdateCart(res.data.data);
            action.resetForm();
          } else {
            toast.warn(res.data.message, {
              autoClose: 2000,
              theme: "colored",
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "colored",
          });
        });
    },
  });

  return (
    <>
      <div
        className="section page-banner-section bg-color-1 cart-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/cart-banner.png"
          )`,
        }}
      >
        <div className="container">
          {isModalVisible && (
            <PromoCodeModal
              showModal={isModalVisible}
              onClose={() => setModalVisible(false)}
              promoCodesData={promoCodeList}
            />
          )}
          <div className="course-details-banner-content shopping-cart">
            <h1 className="title">
              <p>
                Cosmos IQ is chosen by top organizations and institutions to
                help them develop in-demand career skills.
              </p>
              Shopping Cart
            </h1>
          </div>
        </div>
      </div>
      {isEmpty ? (
        <>
          <section className="cart-empty">
            <div className="container h-50">
              <div className="cart-empty-inner">
                <h5>Your cart is empty</h5>
                <Link to={"/filter"} className="cont_shop">
                  Explore our courses
                </Link>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="cart-section">
            <div className="container">
              <div className="row">
                <h2 className="cart-head">Order Summary</h2>
                <div className="col-md-7">
                  <div className="summary-section">
                    {items.map((item, index) => (
                      <div key={index} className="summary-outer">
                        <div className="summary-left">
                          <div className="summary-left-one">
                            <div className="summary-left-img">
                              <img src={item.image} alt={item.subjectName} />
                            </div>
                            <div className="summary-left-content">
                              <h4>{item.subjectName}</h4>
                              <p>{item.courseName}</p>
                            </div>
                          </div>
                        </div>
                        <div className="summary-right">
                          <Link
                            variant="danger"
                            onClick={() => removeItem(item.id)}
                            className="text-danger"
                          >
                            <i className="fa fa-trash close-summary text-danger"></i>
                          </Link>
                          <h5 className="summary-price">
                            <i className="fa fa-inr"></i>
                            {item.price}
                          </h5>
                          {/* <p className="summary-cut">
                            <i className="fa fa-inr"></i>
                          </p> */}
                        </div>
                      </div>
                    ))}

                    <div className="recommend mt-3">
                      {recommend ? (
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey={0}>
                            <Accordion.Header>
                              Recommended For You
                            </Accordion.Header>
                            <Accordion.Body>
                              {recommend.map((item, index) => (
                                <div key={index + 1} className="recommend-head">
                                  <div className="summary-left">
                                    <div className="summary-left-one">
                                      <div className="summary-left-img">
                                        <img
                                          src={item.subjectPicture}
                                          alt={item.subjectName}
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="summary-left-content">
                                        <h4>
                                          <Link
                                            to={`/courses/${item.courseNameSlugUrl}/${item.subjectNameSlugUrl}`}
                                          >
                                            {item.subjectName}
                                          </Link>
                                        </h4>
                                        <p className="price-recommend">
                                          <i
                                            className="fa fa-inr"
                                            style={{
                                              color: "#18254a",
                                              paddingRight: "2px",
                                            }}
                                          ></i>
                                          {item.subjectPrice}
                                          {/* <span>
                                      <i className="fa fa-inr"></i>0.00
                                    </span> */}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="recommend-list">
                                      {item.subjectLongDescription}
                                    </div>
                                    <hr />
                                  </div>
                                  <div className="get-btn">
                                    <Link
                                      to={`/courses/${item.courseNameSlugUrl}/${item.subjectNameSlugUrl}`}
                                      className="get-btn-btn"
                                    >
                                      Get It
                                    </Link>
                                  </div>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="subtotal-section">
                    <h3>Order Summary</h3>
                    <p>{totalUniqueItems} items</p>
                    <div className="subtotal-price">
                      <h4>Total MRP</h4>
                      <p>
                        <i className="fa fa-inr text-dark"></i>&nbsp;
                        <span id="totalMRP">{cartTotal}</span>
                      </p>
                    </div>

                    {isDiscountApplied ? (
                      <>
                        <div className="subtotal-price">
                          <h4>Discounted Coupon</h4>
                          <p>
                            <i className="fa fa-inr text-dark"></i>&nbsp;
                            <span id="discountedCouponPrice">
                              {couponPrice}
                            </span>
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <hr />
                    <div className="subtotal-price">
                      <h4>Total Amount (INR)</h4>
                      <p>
                        <i className="fa fa-inr text-dark"></i>&nbsp;
                        <span id="totalAmount">
                          {totalOfferAmount ? totalOfferAmount : cartTotal}
                        </span>
                      </p>
                    </div>
                    <div className="subtotal-description">
                      <p>Total amount does not include applicable taxes</p>
                      {isDiscountApplied ? (
                        <p>
                          Applied Promo Code - <b>{couponCode}</b>{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      <form
                        onSubmit={handleSubmit}
                        id="applyCode"
                        autoComplete="off"
                      >
                        <div className="d-flex justify-content-center gap-3">
                          <input
                            type="hidden"
                            name="cartTotal"
                            value={cartTotal}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <input
                            style={{
                              width: "60%",
                              fontSize: "14px",
                              padding: "0 5px",
                            }}
                            placeholder="Promo code"
                            type="text"
                            name="couponCode"
                            id="couponCode"
                            value={values.couponCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <button
                            type="submit"
                            className="ready-btn text-white my-0"
                            style={{ fontSize: "13px", padding: "4px 16px" }}
                            disabled={!(dirty && isValid && !isSubmitting)}
                          >
                            {isSubmitting ? "..." : "Apply"}
                          </button>
                        </div>
                        {errors.couponCode && touched.couponCode ? (
                          <p className="text-danger">{errors.couponCode}</p>
                        ) : null}
                      </form>
                      <p>
                        Don't Have a Promo Code?{" "}
                        <span
                          onClick={handleButtonClick}
                          style={{ cursor: "pointer" }}
                          className="fw-bold"
                        >
                          Click here
                        </span>
                      </p>
                      <p>
                        {isDiscountApplied ? (
                          <>
                            <i className="fa fa-check"></i>{" "}
                            <span>VALID PROMO CODE.</span>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        You're getting the best price we have got
                      </p>

                      <Link
                        to={
                          localStorage.getItem("authUser")
                            ? "/checkout"
                            : STUDENTLINK
                        }
                        className="ready-btn text-white"
                      >
                        I'm Ready to pay
                      </Link>
                      <p>Nice!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Cart;
